import React from "react";

function Header() {
  return (
    <div id="header">
      <div className="colored"></div>

      <div className="menu-bar">
        <nav className="navbar nav-1 navbar-expand-lg navbar-light">
          <div>
            <a className="navbar-brand" href="https://makemynotes.com">
              <img src="images/13.png" alt="" />
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/about">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/login">
                  Test Series
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/">
                  Latest Article
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}
export default Header;
