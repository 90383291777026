import React from "react";

function Post() {
  return (
    <section id="profile-posts">
      <div className="container">
        <h3>Saved Notes</h3>
        <div className="post">
          <div className="d-flex p-3 space">
            <img
              src="images/sanoj.jpeg"
              width="50em"
              className="mx-3"
              height="50em"
              style={{
                borderRadius: "50%",
              }}
            />
            <div>
              <h3 className="text-muted">Hey Satyam Salvi! Create a Post</h3>
              <p className="text-muted">Start typing here...</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Post;
