import React from "react";

function Terms() {
  return (
    <section id="mmn">
      <div className="terms-div text-justify">
        <br />
        <h5 className="terms-headings">Terms And Conditions</h5>
        <p>
          The content and documents published by Make My Notes (MMN) are in
          accordance with the provisions of the Information Technology
          (Intermediaries Guidelines) Rules, 2011 that require publishing the
          rules and regulations, privacy policy and terms of use for access or
          uses of Make My Notes online learning platform. Any person, whether
          legal or natural by using, accessing or participating in the Service,
          agrees to the terms of this privacy policy (the "Privacy Policy").
          Whoever uses any of the services provided by Make My Notes through the
          website and/or application are governed by the rules and terms of uses
          framed hereunder. By accessing or using any platform of Make My Notes,
          It will be assumed that you have read, understood, and agreed and to
          be bound by these Terms and Conditions including any future
          modifications. Make My Notes may amend, update, modify or change these
          terms and conditions as he thinks necessary to do so and such changes
          will be updated on the website in respective column with the date of
          revision and any such changes or revision of terms and conditions made
          available for the public and users will be bound by such revisions. If
          you don’t agree with Terms and Services you are not authorised to use,
          access or participate in the services.
        </p>
        <br />
        <h5 className="terms-headings">Make My Notes: Description and Services</h5>
        <p>
          We offer to our users the services of preparation of all government
          examinations online and Make my notes has the sole right to change,
          update, improve, modify, suspend and discontinue any part of services
          either temporarily or permanently.
        </p>
        <br />
        <h5 className="terms-headings">Make My Notes: Registration</h5>
        <p>
          While registering of the services provided by Make my notes users
          agree. (i) to provide their True, current and fair information about
          you and/or your organization as required (ii) to maintain the
          confidentiality and privacy of your password and other information
          related to the security of users account (iii) to be sole responsible
          for all use of account and for any actions that take place through
          users account (iv) As you are signing up on Make My Notes it is
          assumed that you have given your consent to be contacted by us via
          phone calls, mail and/or SMS notifications and offer you our services,
          relating to product knowledge, offers running on website/app (v)
          Users/candidates are requested to provide their government examination
          registration information for the purpose of Make My Notes internal
          estimation of successful candidates. If candidates do not provide the
          information required, their partial or full access to the website
          content and/or other Make My Notes services may be blocked and No
          refund will be provided in such scenario and we assure you that we
          will not use this information for any other purpose or share this
          information with any third party.
        </p>
        <br />
        <h4 className="terms-headings">
          Eligibility to access the services of MMN
        </h4>
        <p>
          Any person who is agreeing to these Terms of Use, represents that they
          are legally competent to enter into an agreement and provide consent
          to these Terms of Use. You further represent that 1. Person is sound
          minded and. 2. Are not prohibited from entering into a legally binding
          contract as per applicable laws. In case the Platform is being
          accessed, and the Services are being availed for the benefit of a
          minor, you expressly confirm that you are legally competent to provide
          consent on behalf of such a minor and that the minor’s use of the
          Platform and/or the Services shall be subject to the Terms of Use.
        </p>
        <br />
        <h4 className="terms-headings">Your Account</h4>
        <p>
          You can become a registered user of the MMN Platform by setting up a
          password-protected account with us. In order to set up such an
          account, you will be required to enter the details of your registered
          email id/Mobile No and password. By setting up an account, you agree
          to accept any and all responsibility for any and all activities that
          occur under your account. By setting up the account, you further agree
          to the contents of the Privacy Policy. Make My Notes keep the right
          and sole discretion to refuse access to the Platform, terminate any
          account, remove or restrict any content, at any time, with or without
          notice to you in case we notice any illegal activity on or from
          account or if we have reason to believe that any information provided
          by you is unfair, untrue, inaccurate, outdated or incomplete.
        </p>
        <br />
        <h4 className="terms-headings">Content Posting Guidelines for users</h4>
        <p>
          Subscribers of MMN may post their queries and answer other questions
          in the student community /ask and answer section on the MMN platform.
          Posting is not restricted unless it violates the law, defamatory
          depiction for a specific individual and constitutes a genuine threat
          and harassment. When you submit or upload any content on the Platform,
          you represent and warrant that; · Content solely belongs to you and
          does not violate the intellectual property rights or other rights of
          any person or entity, including but not limited to copyright, moral
          rights, trademark, patent or rights of privacy or publicity. · Your
          use of the Platform will comply with all applicable laws, rules and
          regulations Make My Notes reserves the right to review such content
          uploaded by the user and in case any content is found to be a
          violation of any of the Terms of Use and/or any applicable laws, Make
          My Notes may remove any content from the Platform and/or terminate or
          suspend the user account in its sole discretion.
        </p>
        <br />
        <h4 className="terms-headings">Term of sale</h4>
        <p>
          Here seller has made an offer to sell digital products/services by
          listing the same on the Make My Notes Platform and buyer has accepted
          such offer by agreeing to buy the product or use the services, in
          current or future date and the term of sale shall be legally binding
          on the seller and buyer. The terms may contain certain disclaimers
          which will be binding on both the Buyers and the Seller. Users are
          advised to read the Terms of Sale carefully The Terms of Sale are in
          addition to the Terms of Use, agreement, privacy policies, and other
          policies of MMN. MMN can include/exclude/modify Terms of Sale any
          time, which will be binding on the Buyer and the Seller and such
          conditions may or may not be part of this agreement. The consent, by
          acceptance or use, will be considered as binding on the user (Buyer or
          Seller) and will be unconditional, subject to the provisions of the
          Act.
        </p>
        <br />
        <h4 className="terms-headings">Pricing</h4>
        <p>
          The Seller is free to decide the price of a course/study material.
          Once a learner buys a course at a price it can not be changed for the
          rest of the course period. MMN reserves the right to charge for its
          services and to change its fees from time to time in its own
          discretion. In the course of termination of your membership because
          you
          <strong>
            have breached the Agreement, you will not be entitled to the refund
            of any unused portion of subscription fees paid by you.
          </strong>
          In course, if the List Price on the MMN is mentioned incorrectly then
          the Seller or the Company has the option to cancel the order. The
          transaction price includes all taxes, unless otherwise specified.
        </p>
        <br />
        <h4 className="terms-headings">CANCELLATIONS AND REFUND POLICY</h4>
        <p>
          <strong>Cancellations:</strong> As a general rule, all sales made on
          Make My Notes are final and you shall not be entitled to cancel your
          order once you have received confirmation of the same. Make My Notes
          reserves the sole right to cancel any order as per our discretion in
          case (i) we are unable to deliver the order in a satisfactory manner
          and/ or (ii) the user tries to take advantage of the system which
          violates the Terms of Use. Make My Notes will ensure that any
          communication of cancellation of an order or any applicable refund
          will be made within a reasonable period of time.
          <br />
          <strong>Refunds:</strong> MMN has sole right to decide at its own
          discretion to consider any cancellation/refund request on case to case
          basis and MMN is not under any obligation to provide a refund except
          where it deems appropriate however you shall be entitled to a refund
          only if Make My Notes is unable to deliver your order. For refund
          requests please email to ..........................................
          within 1 week of the date of purchase. All refunds will be processed
          on a prorated basis, depending on the service already delivered by
          MMN. Refunds will be done directly to the original payment mode within
          seven working days of finalization of the claim.
          <br />
          <strong>Governing Law:</strong> Terms of sale shall be governed by the
          law of India without reference to any conflicts of principles. The
          place of jurisdiction shall be exclusively in New Delhi.
        </p>
        <br />
        <h4 className="terms-headings">Amendments to the terms and conditions</h4>
        <p>
          Make My Notes may amend the Terms of Use and other Make My Notes
          Policies published on the Platform from time to time at its sole
          discretion. Your continued access or use of the Platform and the
          Services constitutes your acceptance of the amendments. Your access
          and use of the Platform and Services will be subject to the most
          current version of the Terms of Use, Privacy Policy and Copyright
          Policy posted on the Platform at the time of such use. Please
          regularly check the Platform to view the latest version of Make My
          Notes Policies.
        </p>
      </div>
    </section>
  );
}

export default Terms;
