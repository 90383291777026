import React, { Component } from "react";
import { Helmet } from "react-helmet";
import SpecificTestseries from "../../Components/Testseries/SpecificTestseries";

// import Sidebar from "../../Components/Dashboard/Sidebar";

class Testseries extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet titleTemplate="MMN | %s" title="Testseries" />
                
                {/* <Sidebar /> */}
                <SpecificTestseries />
                
            </React.Fragment>
        );
    }
}

export default Testseries;