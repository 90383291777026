import React from "react";

function Cards() {
  return (
    <section id="mmn">
      <div className="card-deck ">
        <div className="card ">
          <img
            className="card-img-top"
            src="images/exam.png"
            alt="Card image cap"
          />
          <div className="card-body ">
            <h5 className="card-title">
              <a href="#">Exams</a>
            </h5>
          </div>
        </div>
        <div className="card">
          <img
            className="card-img-top"
            src="images/course.jpg"
            alt="Card image cap"
          />
          <div className="card-body">
            <h5 className="card-title">
              <a href="#">Courses</a>
            </h5>
          </div>
        </div>
        <div className="card">
          <img
            className="card-img-top"
            src="images/resourse.png"
            alt="Card image cap"
          />

          <div className="card-body">
            <h5 className="card-title">
              <a href="#">Resources</a>
            </h5>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cards;
