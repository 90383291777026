import React, { Component } from "react";
import { Helmet } from "react-helmet";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Cards from "../../Components/Home/Cards";
import Upcoming from "../../Components/Home/Upcoming";
import Counsel from "../../Components/Home/Counsel";
import StudentFly from "../../Components/Home/StudentFly";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import NewsletterForm from "../../Components/Home/NewsLetterForm";
import Post from "../../Components/Home/Post";

class Home extends Component {
  // componentDidMount = () => {
  //   const script = document.createElement("script");

  //   script.src = "https://static.saleassist.ai/widgets/widget.js";
  //   // script.async = true;

  //   document.body.appendChild(script);
  // };
  render() {
    const postUrl = `https://iitr.us5.list-manage.com/subscribe/post?u=38e867f19ce93f772145f5a64&id=331f5c63fb`;

    return (
      <React.Fragment>
        <Helmet titleTemplate="MMN | %s" title="Home" />
        <Helmet>
          <script src="https://static.saleassist.ai/widgets/widget.js"></script>
        </Helmet>
        <Header />
        <Post />
        <Upcoming />
        <StudentFly />
        <Counsel />
        <Cards />

        <div className="mailchimp">
          <MailchimpSubscribe
            url={postUrl}
            render={(props) => {
              const { subscribe, status, message } = props || {};
              return (
                <NewsletterForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              );
            }}
          />
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
