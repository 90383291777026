import React, { Component } from "react";
import { Helmet } from "react-helmet";
import TestScore from "../../Components/ScoreCard/TestScore";

class ScoreCard extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet titleTemplate="MMN | %s" title="ScoreCard" />
        <TestScore />
      </React.Fragment>
    );
  }
}

export default ScoreCard;
