import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Instructions from "../../Components/TestInstructions/InstructionsNext";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet titleTemplate="MMN | %s" title="Test Instructions" />
        <Instructions />
      </React.Fragment>
    );
  }
}

export default Home;
