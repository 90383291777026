import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

function InstructionsNext() {
  return (
    <section id="instructions-next">
      <h3 className="text-center mt-4">SSC-CGL 2020 Tier 1-Mock Test</h3>
      <br />
      <span>
        <p className="text-left position-absolute font-weight-bold ml-4">
          Duration: 60 Mins
        </p>
        <p className="text-right font-weight-bold mr-4">Maximum marks: 50</p>
      </span>
      <p className="ml-4 font-weight-bold">
        Read the following instructions carefully
      </p>
      <ul className="ml-5">
        <li>The test contains 4 sections having 100 total questions.</li>
        <li>Each question has 4 options out of which only one is correct.</li>
        <li>You have to finish the test in 60 minutes.</li>
        <li>Try not to guess the answer as there is negative marking.</li>
        <li>
          You will be awarded 2 marks for each correct answer and 0.5 marks will
          be deducted for each wrong answer.
        </li>
        <li>
          There is no negative marking for the questions that you have not
          attempted.
        </li>
        <li>
          You can write this test only once. Make sure that you complete the
          test before you submit the test and/or close the browser.
        </li>
      </ul>
      <div className="declaration fixed-bottom p-3">
        <p>Choose your default language:</p>
        <select>
          <option value="english">English</option>
          <option value="hindi">Hindi</option>
        </select>
        <p className="text-danger">
          Please note that all questions will appear in your default language.
          This language can be changed for a particular question later on.
        </p>
        <p>Declaration:</p>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox name="checked" color="primary" />}
            label="I have read all the instructions carefully and have understood them. I agree not to cheat or use unfair means in this examintaion. I understand that using unfair means of any sort for my own or sommeone ekse's advantage will lead to my immediate disqualification. The decision of MakeMyNotes.com will be final in these matters and cannot be appealed."
          />
        </FormGroup>
        <a className="" href="/test-engine">Next</a>
      </div>
    </section>
  );
}

export default InstructionsNext;
