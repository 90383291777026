import React from "react";

function Profile() {
  return (
    <section id="user-profile">
      <div className="container profile-container">
        <div className="row">
          <div className="col-lg-4 col-sm-12 d-contents contents">
            <img
              src="images/sanoj.jpeg"
              width="200px"
              height="200px"
              style={{
                borderRadius: "50%",
                margin: "auto",
                marginBottom: "40px",
              }}
            />
          </div>
          <div className="col-lg-8 col-sm-12">
            <div className="d-flex">
              <h2>Satyam Salvi</h2>
              <button className="edit-button">Edit Profile</button>
            </div>
            <br />
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-12 profile-block">0 Posts</div>
                <div className="col-lg-4 col-12 profile-block text-center">
                  0 Followers
                </div>
                <div className="col-lg-4 col-12 text-center">0 Followings</div>
              </div>
            </div>
            <br />
            <button className="button-profile">Back & In...</button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Profile;
