import React, { Component } from "react";
const TestScore = () => {
  return (
    <section id="testscore">
      <div className="container">
        <h2>Thank you for attempting SSC GD Constable 2021: Live Test 4</h2>
        <div className="card-deck">
          <div className="card">
            <div className="card-body">
              <img
                className="img-right"
                src="/images/medal.png"
                alt=""
                width="100px"
                height="200px"
              />
              <h3 className="card-title">Rank</h3>
              <br />
              <p className="card-text">
                <span className="rank">17231</span>&nbsp;
                <span className="text-muted out-of">/23298</span>
              </p>
              <br />
              <p className="text-muted">
                {" "}
                Well Done! Keep up the <br /> hard work.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="d-flex">
                <div className="d-flex m-auto">
                  <img
                    src="/images/medal.png"
                    alt=""
                    width="20px"
                    height="40px"
                    className="my-auto"
                  />
                  <div className="ml-3">
                    <p className="text-muted" style={{ marginBottom: "0px" }}>
                      Score
                    </p>
                    <p>0/100</p>
                  </div>
                </div>
                <div className="d-flex m-auto">
                  <img
                    src="/images/medal.png"
                    alt=""
                    width="20px"
                    height="40px"
                    className="my-auto"
                  />
                  <div className="ml-3">
                    <p className="text-muted" style={{ marginBottom: "0px" }}>
                      Attempts
                    </p>
                    <p>0/100</p>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="d-flex">
                <div className="d-flex m-auto">
                  <img
                    src="/images/medal.png"
                    alt=""
                    width="20px"
                    height="40px"
                    className="my-auto"
                  />
                  <div className="ml-3">
                    <p className="text-muted" style={{ marginBottom: "0px" }}>
                      Speed
                    </p>
                    <p>0/100</p>
                  </div>
                </div>
                <div className="d-flex m-auto">
                  <img
                    src="/images/medal.png"
                    alt=""
                    width="20px"
                    height="40px"
                    className="my-auto"
                  />
                  <div className="ml-3">
                    <p className="text-muted" style={{ marginBottom: "0px" }}>
                      Accuracy
                    </p>
                    <p>0/100</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body d-flex">
              <img
                src="/images/medal.png"
                alt=""
                width="100px"
                height="200px"
              />
              <div className="text-right m-auto">
                <div className="d-flex">
                  <span class="dot bg-success m-auto"></span>&nbsp;&nbsp;
                  <p className="text-muted m-auto"> Correct</p>&nbsp;&nbsp;
                  <p className="m-auto text-success">0</p>
                </div>
                <br />
                <div className="d-flex">
                  <span class="dot bg-danger m-auto"></span>&nbsp;&nbsp;
                  <p className="text-muted m-auto"> Incorrect</p>&nbsp;&nbsp;
                  <p className="m-auto text-danger">0</p>
                </div>
                <br />
                <div className="d-flex">
                  <span class="dot bg-secondary m-auto"></span>&nbsp;&nbsp;
                  <p className="text-muted m-auto"> Skipped</p>&nbsp;&nbsp;
                  <p className="m-auto text-muted">0</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <p className="text-center testscore-p">Detailed results are announced</p>
      <div className="btn-center">
        <button className="btn">Solutions</button>
      </div>

      <br />
    </section>
  );
};

export default TestScore;
