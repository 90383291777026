import React from "react";
import Saleassist from "../Saleassist/Saleassist";
function StudentFly() {
  return (


    <section id="graphic">
      


      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 student-flying">
            <img src="images/student_fly.svg" alt="student-flying" max-width="30rem" />
          </div>
          <div className="col-md-6  student-flying-1">
            <h1 style={{fontSize:"2.5rem"}}>Why Make My Notes ?</h1>
            <br />

            <p className= "text-justify">
              We at Make My Notes provide you a single window solution to
              practice online mock tests for different competitive exams that
              assist you to achieve right destination with ease. At makemynotes
              you can test yourself with a wide range of test series along with
              free mock test that will help you to prepare online for different
              competitive exam.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default StudentFly;
