import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import Profile from "../../Components/UserProfile/Profile";
import About from "../../Components/UserProfile/About";
import Post from "../../Components/UserProfile/Post";
class ProfilePage extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet titleTemplate="MMN | %s" title="User Profile" />
        <Header />
        <br />
        <br />
        <Profile />
        <br />
        <br />
        <About />
        <br />
        <br />
        <Post />
        <br />
        <Footer />
      </React.Fragment>
    );
  }
}

export default ProfilePage;
