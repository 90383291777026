import React from "react";
import { CDBSidebarMenuItem } from "cdbreact";

function About() {
  return (
    <section id="about-profile">
      <div className="container about-con">
        <h3>About me</h3>
        <br />
        <div className="d-flex">
          <CDBSidebarMenuItem icon="clock" className="about-icon">
            &nbsp;&nbsp; Member since Jun 2021
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem icon="question" className="about-icon">
            &nbsp;&nbsp; 0 Dounts Answered and 0 Best Answers
          </CDBSidebarMenuItem>
        </div>
        <div className="d-flex">
          <CDBSidebarMenuItem icon="heart" className="about-icon">
            &nbsp;&nbsp; 0 Thanks Received
          </CDBSidebarMenuItem>
          <CDBSidebarMenuItem icon="file" className="about-icon">
            &nbsp;&nbsp; 0 Questions Answered Correctly
          </CDBSidebarMenuItem>
        </div>
      </div>
      <br />
    </section>
  );
}

export default About;
