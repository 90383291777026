import React from "react";

function Upcoming() {
  return (
    <section id="upcoming">
      <div className="container">
        <a href="/login" className="link-to">
        <h1 className="heading">
          <strong style={{fontSize:"2.5rem"}} className="sec-heading"> Upcoming and Popular Exams</strong>
        </h1>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-6 exam">
            <div className="logo">
              <div className="logo_img">
                <img src="/images/ssc_cgl.png" alt="SSC CGL" />
              </div>
              <p>
                <strong>SSC CGL</strong>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 exam">
            <div className="logo">
              <div className="logo_img">
                <img src="/images/ssc_cgl.png" alt="SSC CHSL" />
              </div>
              <p>
                <strong>SSC CHSL</strong>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 exam">
            <div className="logo">
              <div className="logo_img">
                <img src="/images/ssc_cgl.png" alt="SSC MTS" />
              </div>
              <p>
                <strong>SSC MTS</strong>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 exam">
            <div className="logo">
              <div className="logo_img">
                <img src="/images/ssc_cgl.png" alt="SSC GD CONSTABLE" />
              </div>
              <p>
                <strong>SSC GD CONSTABLE</strong>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 exam">
            <div className="logo">
              <div className="logo_img">
                <img src="/images/bpsc.png" alt="BPSC" />
              </div>
              <p>
                <strong>BPSC</strong>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 exam">
            <div className="logo">
              <div className="logo_img">
                <img src="/images/bpsc.png" alt="BPSC AAO" />
              </div>
              <p>
                <strong>BPSC AAO</strong>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 exam">
            <div className="logo">
              <div className="logo_img">
                <img src="/images/bpsc.png" alt="BPSC PANCHAYAT AUDITOR " />
              </div>
              <p>
                <strong>BPSC PANCHAYAT AUDITOR </strong>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 exam">
            <div className="logo">
              <div className="logo_img">
                <img src="/images/uppsc.png" alt="UPPSC" />
              </div>
              <p>
                <strong>UPPSC</strong>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 exam">
            <div className="logo">
              <div className="logo_img">
                <img src="/images/rrb_ntpc1.jpg" alt="RRC GROUP D" />
              </div>
              <p>
                <strong>RRC GROUP D</strong>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 exam">
            <div className="logo">
              <div className="logo_img">
                <img src="/images/rrb_ntpc1.jpg" alt="RRB NTPC" />
              </div>
              <p>
                <strong>RRB NTPC</strong>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 exam">
            <div className="logo">
              <div className="logo_img">
                <img src="/images/ibps.png" alt="IBPS CLERK" />
              </div>
              <p>
                <strong>IBPS CLERK</strong>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 exam">
            <div className="logo">
              <div className="logo_img">
                <img src="/images/sbi_po.jpg" alt="SBI PO" />
              </div>
              <p>
                <strong>SBI PO</strong>
              </p>
            </div>
          </div>
        </div>
        </a>
      </div>
    </section>
  );
}

export default Upcoming;
