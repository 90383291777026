import React from "react";

function AboutPage() {
  return (
    <section id="about-page">
      <div className="row about-us">
        <div className="col-md-6 part_1">
          <h3>Who we are</h3>
          <p>
            Make My Notes is leading online test portal, which came in existence
            to provide best offers for assessment of exam preparation of India.
          </p>
          <p>We are ready to make online assessment more transparent.</p>
        </div>
        <div className="col-md-6 part_2">
          <h3>What we do</h3>
          <p>
            We have started to provide a common platform to all applicants to
            check their preparation on different upcoming exam.
          </p>
          <p>
            Nowadays, India has recognized presence in online preparation and
            also have tremendous opportunity for assessment of the knowledge
            gained after study.
          </p>
        </div>
      </div>

      <section id="team">
        <div className="container my-3 py-5 text-center">
          <div className="row mb-5">
            <div className="col">
              <h1>Our Team</h1>
              <p className="mt-3"> It is a long eastablished fact.</p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="card-body">
                  <img
                    src=""
                    alt=""
                    className="img-fluid rounded-circle w-50 mb-3"
                  />
                  <h3>Deepak Shukla</h3>
                  <h5>Director</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <div className="d-flex flex-row justify-content-center">
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://www.facebook.com/makemy.notes"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </div>
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/make-my-notes/about/?viewAsMember=true"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://twitter.com/MakeMyNotes1"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </div>
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://www.instagram.com/_makemynotes_/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="card-body">
                  <img
                    src=""
                    alt=""
                    className="img-fluid rounded-circle w-50 mb-3"
                  />
                  <h3>Prateek Singh</h3>
                  <h5>Director</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <div className="d-flex flex-row justify-content-center">
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://www.facebook.com/makemy.notes"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </div>
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/make-my-notes/about/?viewAsMember=true"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://twitter.com/MakeMyNotes1"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </div>
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://www.instagram.com/_makemynotes_/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="card-body">
                  <img
                    src=""
                    alt=""
                    className="img-fluid rounded-circle w-50 mb-3"
                  />
                  <h3>Sanoj Kumar</h3>
                  <h5>Content Head</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <div className="d-flex flex-row justify-content-center">
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://www.facebook.com/makemy.notes"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </div>
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/make-my-notes/about/?viewAsMember=true"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://twitter.com/MakeMyNotes1"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </div>
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://www.instagram.com/_makemynotes_/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="card-body">
                  <img
                    src=""
                    alt=""
                    className="img-fluid rounded-circle w-50 mb-3"
                  />
                  <h3>Arshad Khan</h3>
                  <h5>Developer</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <div className="d-flex flex-row justify-content-center">
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://www.facebook.com/makemy.notes"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </div>
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/make-my-notes/about/?viewAsMember=true"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://twitter.com/MakeMyNotes1"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </div>
                    <div className="p-4">
                      <a
                        target="_blank"
                        href="https://www.instagram.com/_makemynotes_/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default AboutPage;
