import React from "react";
import QPanel from "./QPanel";
import QPanelinfo from "./QPanelinfo";
function SideBar() {
  return (
    <div id="sidebar">
      <div className="ine-one-line">
        <div className="text-center user-round">R</div>&nbsp;<p>Ravi</p>
        </div>
        <hr />
        <QPanelinfo/>
        <hr />
        <QPanel/>
        <hr />
      <div id="footer" >
      <button className="footer-btn">Question Paper</button>
      <button className="footer-btn">Instructions</button>
      </div>
      <button className="footer-btn" id="submit">Submit Test</button>
    </div>
  );
}

export default SideBar;
