import React from "react";

function QPanelinfo() {
  return (
    <div id="qpanel-info">
      <div className="ine-one-line">
          <p className="answered half-circle"> <div className="text-center">1</div></p> &nbsp; <p>Answered</p>
      </div>
      &nbsp;&nbsp;
      <div className="ine-one-line">
          <p className="marked half-circle"> <div className="text-center">10</div></p> &nbsp;<p>Marked</p>
      </div>
      &nbsp;&nbsp;
      <div className="ine-one-line">
          <p className="not-visited"> <div className="text-center">7</div></p> &nbsp;<p>Not Visited</p>
      </div>
      &nbsp;&nbsp;
      <div className="ine-one-line">
          <p className="marked-and-ans half-circle"> <div className="text-center">4</div></p> &nbsp; <p>Marked & Answered</p>
      </div>
      &nbsp;&nbsp;
      <div className="ine-one-line">
          <p className="not-answered"> <div className="text-center">14</div></p> &nbsp;<p>Not Answered</p>
      </div>
    </div>
  );
}

export default QPanelinfo;