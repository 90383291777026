import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "../../Components/TestEngine/Header"
import Header1 from "../../Components/TestEngine/Header1";
import Header2 from "../../Components/TestEngine/Header2";
import SideBar from "../../Components/TestEngine/SideBar";
import Question from "../../Components/TestEngine/Question";

class TestEngine extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet titleTemplate="MMN | %s" title="TestEngine" />
        <Header/>
        <div id="testengine">
        <div id="left">
        <Header1/>
         <Header2/>
         <Question/>         
        </div>
        <div id="right">
         <SideBar/>
         </div>
         </div>
      </React.Fragment>
    );
  }
}

export default TestEngine;
