import React, { useState, useEffect } from "react";
import axios from "axios";

const SpecificTestseries = () => {
  const [totalmarks, setTotalmarks] = useState();
  const [question, setQuestion] = useState();
  const [syllabus, setSyllabus] = useState();
  const [language, setLanguage] = useState();
  const [category, setCategory] = useState();
  const [active, setActive] = useState();
  const [duration, setDuration] = useState();
  const [users, setUsers] = useState();

  useEffect(() => {
    async function getData() {
      const res = await axios.get(
        `http://13.233.149.10:3000/mmn/sub_category/1`
      );
      // console.log("abcd", res.data.result[0].total_marks);

      setTotalmarks(res.data.result[0].total_marks);
      setQuestion(res.data.result[0].number_of_questions);
      setSyllabus(res.data.result[0].syllabus);
      setLanguage(res.data.result[0].sub_category_language);
      setCategory(res.data.result[0].category_name);
      setActive(res.data.result[0].is_active);
      setDuration(res.data.result[0].duration);
      setUsers(res.data.result[0].sub_category_number_of_students_registered);
    }

    getData();
  });

  return (
    <section id="specific-test-series">
      <div className="container">
        <br />
        <h5 className="card-title">Free Mock Test</h5>
        <div className="card">
          <div className="card-body">
            <p id="para1">FREE</p>
            <div className="div-inline">
              <h5 className="card-title">{category}</h5> &nbsp; &nbsp;
              <p>
                <img src="/images/light.svg" alt="" />
                {users} active Users
              </p>
              <a href="/testInstruction">
                <button className="view-btn">Start Now</button>
              </a>
            </div>
            <div className="div-inline">
              <img
                className="icon"
                src="/images/question_mark.png"
                alt=""
                width="10px"
                height="10px"
              />
              &nbsp;
              <p> {question} Questions</p>&nbsp;&nbsp;&nbsp;
              <img
                className="icon"
                src="/images/file.png"
                alt=""
                width="10px"
                height="10px"
              />
              &nbsp;
              <p>{totalmarks} Marks</p>&nbsp;&nbsp;&nbsp;
              <img
                className="icon"
                src="/images/clock.png"
                alt=""
                width="10px"
                height="10px"
              />
              &nbsp;
              <p>{duration} Mins</p>&nbsp;&nbsp;&nbsp;
            </div>
          </div>
          <div className="card-header div-inline">
            <p id="para">{syllabus}</p>&nbsp;&nbsp;&nbsp;
            <img
              className="language"
              src="/images/eng_hindi.png"
              alt="English,Hindi"
              width="15px"
            />
            &nbsp;&nbsp;
            <p>{language}</p>
          </div>
        </div>
        <br />
      </div>
    </section>
  );
};
export default SpecificTestseries;
