import React, {useState} from "react";
import { useAuth } from "../../contexts/AuthContext"
// import { CDBSidebarMenuItem } from "cdbreact";

const Navbar = (props) => {
 const  [DropDown, setDropDown] = useState(false);
 const user = useAuth();
  return (
    // <header className="header" id="header">
    //   <div className="search">
    //     <form action="" class="search-bar">
    //       <input type="search" name="search" pattern=".*\S.*" required />
    //       <button class="search-btn" type="submit">
    //         <span>Search</span>
    //       </button>
    //     </form>
    //     <CDBSidebarMenuItem
    //       className="bell-icon"
    //       icon="bell"
    //     ></CDBSidebarMenuItem>
    //     <div class="dropdown">
    //       <button
    //         class="btn btn-secondary dropdown-toggle"
    //         type="button"
    //         id="dropdownMenuButton1"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         A
    //       </button>
    //       <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    //         <li>
    //           <a class="dropdown-item" href="#">
    //             Refer & Earn
    //           </a>
    //         </li>
    //         <li>
    //           <a class="dropdown-item" href="#">
    //             Settings
    //           </a>
    //         </li>
    //         <li>
    //           <a class="dropdown-item" href="#">
    //             Transactions
    //           </a>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </header>
    <>
    <div className="navbar-container  w-full h-16 bg-gray-50 flex items-center justify-around p-4">
        <div className="brand w-full h-auto"><img src="./images/logo.png" alt="Brand" className="brand-logo h-10 " /></div>
      <div className="search-bar-cont p-1 w-full hidden md:flex">
          <input type="text" className="search-input w-72  h-8  p-2 rounded-full bg-gray-200 " placeholder="Search" aria-describedby="navbarDate"/>
      </div>
      <div className="right-section flex w-1/4 justify-around items-center">
        <div className="notification"><span className="fa fa-bell text-2xl text-gray-400"></span></div>
          <div className="avatar-cont flex items-center space-x-2">
          <div className="avatar w-8 h-8 rounded-full bg-gray-600 border-3 border-gray-200"></div>
          <div className="uc-btn">
          <div className="user-console" >
                <button className="w-4 h-4 flex border-gray-600 border-3 rounded-full justify-center items-center hover:text-gray-50 hover:bg-gray-500" onClick={ props.onClick }> <span className="fa fa-caret-down"></span>  </button>
                <div className={`user-menu w-36 h-auto absolute bg-gray-100 rounded-xl z-30 p-3 right-8 top-16 leading-tighter justify-start ${props.visible}`}>
                  <div className="menu-item p-0"><a href="/" className="item-link text-gray-500  hover:no-underline">Hi! {user.login.name}</a></div>
                  <div className="menu-item p-0"><a href="/" className="item-link text-gray-500  hover:no-underline">Profile</a></div>
                  <div className="menu-item p-0"><a href="/" className="item-link text-gray-500  hover:no-underline">Settings</a></div>
                  <div className="menu-item p-0"><a href="/" className="item-link text-gray-500  hover:no-underline">Transactions</a></div>
                  <div className="menu-item p-0"><a href="/" className="item-link text-gray-500  hover:no-underline">Log out</a></div>
                </div>
           

            {/* {
                DropDown ? <div className="user-menu w-36 h-auto absolute bg-gray-100 rounded-xl flex-col p-3 right-12 top-16 leading-tighter">
                  <div className="menu-item p-0"><a href="/" className="item-link text-gray-500  hover:no-underline">Profile</a></div>
                  <div className="menu-item p-0"><a href="/" className="item-link text-gray-500  hover:no-underline">Account</a></div>
                  <div className="menu-item p-0"><a href="/" className="item-link text-gray-500  hover:no-underline">Status</a></div>
                  <div className="menu-item p-0"><a href="/" className="item-link text-gray-500  hover:no-underline">Log out</a></div>
                </div>: null
            } */}

            </div>
            </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Navbar;
