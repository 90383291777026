import React from "react";
function Footer(){
    return (
        <footer id="test-footer" className="fixed-bottom">
      <button className="footer-btn">Mark for Review & Next</button>
      <button className="footer-btn">Clear Response</button>

          <button className="footer-btn" id="save-btn">Save & Next</button>
      
    </footer>
    )
}

export default Footer;