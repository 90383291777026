import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ForgotPassword from "../../Components/ForgotPassword/ForgotPassword";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet titleTemplate="MMN | %s" title="About" />
        <Header/>
        <ForgotPassword />
        <Footer/>
      </React.Fragment>
    );
  }
}

export default About;
