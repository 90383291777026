import React from "react";
import CountDownTimer from './Timer';
function Header(){
    const hoursMinSecs = {hours:1, minutes: 20, seconds: 40}
    return (
        <div id="test-header">

      <div className="menu-bar">
        <nav className="navbar nav-1 navbar-expand-lg navbar-light">
          <div>
            <a className="navbar-brand" href="https://makemynotes.com">
              <img src="images/1.png" alt="" />
            </a>
            
          </div>
          <p>Classification</p>
          <div className="App">
       <p>Time Left : <CountDownTimer hoursMinSecs={hoursMinSecs}/></p>
    </div>
         
          <div className="header-right">
             <button className="view-btn">Switch Full Screen</button>
             <button className="view-btn">Pause</button>

          </div>
        </nav>
      </div>
    </div>
    )
}

export default Header;