import React from "react"

const MenuItem = (props) => {
    return (
            <div  className="side-menu-item-cont space-x-2  w-min-6  md:w-40 h-8 flex  rounded-lg cursor-pointer justify-start pl-2 items-center  hover:bg-blue-500 hover:text-gray-50 transform hover:duration-700 ease-in-out" onClick={props.onClick} > 
                <div className="item-con w-7 md:w-auto "><span className={props.iname}></span></div>
                <div className="item-text w-36 hidden md:flex">{props.item}</div>
            </div>
    )
}

export default MenuItem