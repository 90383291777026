import React from "react";

function QPanel() {
  return (
    <div id="qpanel">
      <div className="section-heading">
        <span className="text-bold">SECTION : </span>Chapter Test
      </div>
      <br />
      <div id="btn-panel">
      <button className="panel-btn">1</button>
      <button className="panel-btn">2</button>
      <button className="panel-btn">3</button>
      <button className="panel-btn">4</button>
      <button className="panel-btn">5</button>
      <button className="panel-btn">6</button>
      <button className="panel-btn">7</button>
      <button className="panel-btn">8</button>
      <button className="panel-btn">9</button>
      <button className="panel-btn">10</button>
      <button className="panel-btn">11</button>
      <button className="panel-btn">12</button>
      </div>
    </div>
  );
}

export default QPanel;