import React from 'react'


const MiniCard =(props)=>{
    return(
        <div style={{height:"6rem"}} class="smp-item w-64 h-16 flex items-center rounded-lg bg-gray-50 shadow-md p-2 space-x-6 transform hover:scale-105 duration-1000 ease-in-out">
            <div class="crd-img"><img src={`./images/${props.src}`}  alt="" class="crd-img w-12 h-12 rounded-full border-2 border-gray-600" /></div>
            <div class="crd-txt flex-col justify-start">
                <p class="text-1 text-md font-extrabold f-b text-gray-800 w-32 truncate">{props.cat}</p>
                <p class="txt-2 text-sm text-gray-400">{props.paper}&nbsp; Papers</p>
            </div>
            <div class="crd-nav text-gray-700"><span class="fas fa-chevron-right"></span></div>
        </div>

    )
}


export default MiniCard