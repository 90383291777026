import React from "react";

function Footer() {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12 segment-one md-mb-30 sm-mb-30">
              <a className="navbar-brand" href="#Navigation-bar">
                <img src="images/13.png" alt="" />
              </a>

              <p className="footer-note">
                Leading online test series portal for
                competitive examinations.
              </p>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 segment-two md-mb-30 sm-mb-30 foot">
              <h2>Useful Links</h2>
              <ul>
                <li>
                  <a href="/about">About us</a>
                </li>
                <li>
                  <a href="/career">Careers</a>
                </li>

                <li>
                  <a href="#">Privacy policy</a>
                </li>
                <li>
                  <a href="/terms">Terms and Condition</a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 segment-three sm-mb-30 foot">
              <h2>Follow us</h2>
              <p>
                Follow us on our Social Media Profile in order to keep updated.
              </p>
              <a target="_blank" href="https://www.facebook.com/makemy.notes">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a target="_blank" href="https://twitter.com/MakeMyNotes1">
                <i className="fab fa-twitter"></i>
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/make-my-notes/about/?viewAsMember=true"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/_makemynotes_/"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            {/* <div className="col-md-3 col-sm-6 col-xs-12 segment-four sm-mb-30 foot">
              <h2>Our Newsletter</h2>
              <p>
                Subscribe to our newsletter to receive updates about new
                courses, test series and recent updates.
              </p>
              <form className="" action="index.html" method="post" />
              <input type="email" name="email1" />
              <input type="submit" name="" value="Subscribe" />
            </div> */}
          </div>
        </div>
      </div>
      <p className="footer-bottom-text">
        All Right reserved by &copy; MakeMyNotes {currentYear}.
      </p>
    </footer>
  );
}

export default Footer;
