import React from 'react'
import  PCard from './PCard'


const TestSeries = ( ) =>{
    return(
        <>
        <div className="dashboard-section-heading"><p className="dsh-txt text-3xl font-extrabold text-gray-700">Test Series</p></div>
           <div className="test-series-section grid  grid-cols-1 md:grid-cols-4 gap-6 ">
                <PCard src={"ca.png"} cat={"Current Affairs for SSC/R.."} fig={"22.3"} total={"77"} free={"7"}  chap={"25"} sec={"8"} more={"80"} />
                <PCard src={ "crack.jpg" } cat={"Crack it now! With 1500"} fig={"109.4"} total={"93"} free={"6"}  chap={"34"} sec={"9"} more={"60"} />
                <PCard src={"import.jpg"} cat={"Important Days and Evening"} fig={"86.2"} total={"149"} free={"18"}  chap={"22"} sec={"6"} more={"90"} />
                <PCard src={ "ca.png" } cat={"Beginner to Pro with 450+ English"} fig={"25.4"} total={"80"} free={"12"}  chap={"25"} sec={"10"} more={"150"} />

              
            </div>
            </>
    )
}


export default TestSeries