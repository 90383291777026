import React from 'react'
import MiniCard from '../Card/MiniCard'

const PYP=()=>{
    return(
        <>
            <div className="dashboard-section-heading"><p className="dsh-txt text-3xl font-extrabold text-gray-700">Previous Years Paper</p></div>
<div className="pyp-cont-base w-auto h-auto bg-gray-50 p-2 pr-2 rounded-xl">
    <div className="pyp-inner-cont flex ">

         {/* ================ Left Side Menu Display Section ================== */}
        <div className="left-side-pcont w-1/5 bg-gray-50 rounded-tl-xl rounded-bl-xl h-max flex-col ">

                    <div className="choice-item-cont w-auto h-16 bg-gray-50 hover:bg-gray-200 flex items-center space-x-4 p-2">
                        <div className="selection-img"><img src="./images/ssc.png" alt="ssc" className="select-img w-8 h-8 rounded-md" /></div>
                        <div className="selection-text hidden md:flex"><p className="select-txt text-gray-700 text-md hover:font-bold">SSC Exams</p></div>
                    </div>
                    <div className="choice-item-cont w-auto h-16 bg-gray-50 hover:bg-gray-200 flex items-center space-x-4 p-2">
                        <div className="selection-img"><img src="./images/rail.jpg" alt="ssc" className="select-img w-8 h-8 rounded-md" /></div>
                        <div className="selection-text hidden md:flex"><p className="select-txt text-gray-700 text-md hover:font-bold">Railway Exams</p></div>
                    </div>
                    <div className="choice-item-cont w-auto h-16 bg-gray-50 hover:bg-gray-200 flex items-center space-x-4 p-2">
                        <div className="selection-img"><img src="./images/bank.jpg" alt="ssc" className="select-img w-8 h-8 rounded-md" /></div>
                        <div className="selection-text hidden md:flex"><p className="select-txt text-gray-700 text-md hover:font-bold">Banking Exams</p></div>
                    </div>
                    <div className="choice-item-cont w-auto h-16 bg-gray-50 hover:bg-gray-200 flex items-center space-x-4 p-2">
                        <div className="selection-img"><img src="./images/cs.png" alt="ssc" className="select-img w-8 h-8 rounded-md" /></div>
                        <div className="selection-text hidden md:flex"><p className="select-txt text-gray-700 text-md hover:font-bold">Civil Services Exams</p></div>
                    </div>
                    <div className="choice-item-cont w-auto h-16 bg-gray-50 hover:bg-gray-200 flex items-center space-x-4 p-2">
                        <div className="selection-img"><img src="./images/insure.png" alt="ssc" className="select-img w-8 h-8 rounded-md" /></div>
                        <div className="selection-text hidden md:flex"><p className="select-txt text-gray-700 text-md hover:font-bold">Insurance Exams</p></div>
                    </div>
                    <div className="choice-item-cont w-auto h-16 bg-gray-50 hover:bg-gray-200 flex items-center space-x-4 p-2">
                        <div className="selection-img"><img src="./images/eng.jpg" alt="ssc" className="select-img w-8 h-8 rounded-md" /></div>
                        <div className="selection-text hidden md:flex"><p className="select-txt text-gray-700 text-md hover:font-bold">Engineering Exams</p></div>
                    </div>

                    </div>
                 

        
         


        {/* ================ Right Side Paper Display Section ================== */}
                    <div className="right-side-pcont  rounded-lg w-full h-min grid  gap-6 auto-cols-fr grid-cols-1 md:grid-cols-3   bg-gray-200 p-4   mb-0 overflow-y-scroll overflow-x-hidden">
            <MiniCard src="ssc.png" cat="SSC GD Constable" paper="120"/>
            <MiniCard src="ssc.png" cat="SSC CGL" paper="130"/>
            <MiniCard src="ssc.png" cat="SSC CHSL" paper="180"/>
            <MiniCard src="ssc.png" cat="SSC MTS" paper="110"/>
            <MiniCard src="ssc.png" cat="SSC JHT" paper="90"/>
            <MiniCard src="ssc.png" cat="SSC JHT" paper="90"/>
            <MiniCard src="ssc.png" cat="SSC JHT" paper="90"/>
            <MiniCard src="ssc.png" cat="SSC JHT" paper="90"/>
            <MiniCard src="ssc.png" cat="SSC JHT" paper="90"/>
            <MiniCard src="ssc.png" cat="SSC JHT" paper="90"/>
            <MiniCard src="ssc.png" cat="SSC JHT" paper="90"/>
            <MiniCard src="ssc.png" cat="SSC JHT" paper="90"/>
            <MiniCard src="ssc.png" cat="SSC JHT" paper="90"/>
          
       
        </div>
    </div>
</div>
</>
    )
}

export default PYP