import React, { Component, Suspense } from "react";

import routes from "./routes";
// import { AuthProvider } from "../App/contexts/AuthContext";

// class App extends Component {
//   render() {
//     const menu = routes.map((route, index) => {
//       return route.component ? (
//         <Route
//           key={index}
//           path={route.path}
//           exact={route.exact}
//           name={route.name}
//           render={(props) => <route.component {...props} />}
//         />
//       ) : null;
//     });

//     return (
//       <div>
//         {/* <Header /> */}
//         <Suspense fallback={<div />}>
//           <Switch>{menu}</Switch>
//         </Suspense>
//         {/* <Footer /> */}
//       </div>
//     );
//   }
// }

// export default App;

import Signup from "../App/Screens/Signup";
import { Container } from "react-bootstrap";
import { AuthProvider } from "../App/contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "../App/Screens/Dashboard";
import Login from "../App/Screens/Login";
import PrivateRoute from "../App/Screens/PrivateRoute";
import Home from "../App/Screens/Home";
import About from "../App/Screens/About";
import Terms from "../App/Screens/Terms";
import Instructions from "../App/Screens/TestInstructions";
import Testseries from "../App/Screens/Testseries";
import UpdateProfile from "../App/Screens/UpdateProfile";
import ForgotPassword from "../App/Screens/ForgotPassword";
import InstructionsNext from "../App/Screens/TestInstructionsNext";
import TestEngine from "../App/Screens/TestEngine";
import ScoreCard from "../App/Screens/ScoreCard";


import Profile from "../App/Screens/UserProfile";

// import Category1 from "../App/Screens/Category1";
// import ForgotPassword from "./ForgotPassword"
// import UpdateProfile from "./UpdateProfile"

function App() {
  return (
    <div>
      <Router>
        <AuthProvider>
          <Switch>
            <PrivateRoute path="/update-profile" component={UpdateProfile} />
            <Route exact path="/" component={Home} />
            <Route path="/signup" component={Signup} />
            <Route path="/login" component={Login} />
            <Route path="/about" component={About} />
            <Route path="/login" component={Login} />
            <Route path="/terms" component={Terms} />
            <Route path="/testInstruction" component={Instructions} />
            <Route path="/specific-test-series" component={Testseries} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/instruction-next" component={InstructionsNext} />
            <Route path="/test-engine" component={TestEngine} />
            <Route path="/scorecard" component={ScoreCard} />
            

            <Route path="/userprofile" component={Profile} />

            {/* <Route path="/category" component={Category1} /> */}
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
