import React from "react";
import * as Icon from 'react-bootstrap-icons';
import StopWatch from "./StopWatch"
function Header2() {
  return (
    <div id="header2">
      <div className="menu-bar">
        <nav className="navbar nav-1 navbar-expand-lg navbar-light">
          <p>Question No. 1</p>
          <div className="header-right">
              <div>
                  <p>Marks</p>
                  <span><p id="green">+1</p>&nbsp;
                  <p id="red">-0.25</p></span>
              </div>&nbsp;&nbsp;&nbsp;
              {/* <StopWatch/> */}
               <Icon.ExclamationTriangleFill/>&nbsp;<p>Report</p>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header2;
