import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Instructions from "../../Components/TestInstructions/Instructions";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";


class Home extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet titleTemplate="MMN | %s" title="Test Instructions" />
               
                <Instructions />
                
            </React.Fragment>
        );
    }
}

export default Home;