import { useState } from "react";
import { sanitize } from "../../../utils/miscellaneous";


const NewsletterForm = ({ status, message, onValidated }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      setError("Please enter a valid email address");
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf("@") > -1 && isFormValidated;
  };

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = (event) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if ("0" !== result?.[0]?.trim()) {
      return sanitize(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? sanitize(formattedMessage) : null;
  };

  return (
    <div id="newsletter">
      <div className="container">
        <h1 style={{fontSize:"2.5rem"}} className="heading">Join Our Newsletter</h1>
        <div className="newsletter-input-fields">
          <div className="email-box">
            {/* <div className="mc-field-group"> */}
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                onChange={(event) => setEmail(event?.target?.value ?? "")}
                className="tbox"
                onKeyUp={(event) => handleInputKeyEvent(event)}
              />
            {/* </div> */}
            {/* <div className="button-wrap wp-block-button"> */}
              <button
                className="btn cursor-pointer	text-white bg-indigo-500 focus:outline-none hover:bg-indigo-600 rounded"
                onClick={handleFormSubmit}
              >
                Subscribe
              </button>
            {/* </div> */}
          </div>
        </div>
        <div className="min-h-42px">
          {/* {"sending" === status ? (
            <Loading
              showSpinner
              message="Sending..."
              contentColorClass="text-white"
              hasVisibilityToggle={false}
            />
          ) : null} */}
          {"error" === status || error ? (
            <div
              className="text-red-700 pt-2"
              dangerouslySetInnerHTML={{ __html: error || getMessage(message) }}
            />
          ) : null}
          {"success" === status && "error" !== status && !error && (
            <div
              className="text-green-200 font-bold pt-2"
              dangerouslySetInnerHTML={{ __html: sanitize(message) }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsletterForm;
