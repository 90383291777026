import React from "react";
import Footer from "../../Components/TestEngine/Footer";

function Question() {
  return (
    <div id="question">
      <p>Select the most appropriate synoynm of the given word :</p>
      <p>Charming</p>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault1"
        />
        <label class="form-check-label" for="flexRadioDefault1">
          Unhappy
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Pleasant
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault3"
        />
        <label class="form-check-label" for="flexRadioDefault3">
          Unattractive
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault4"
        />
        <label class="form-check-label" for="flexRadioDefault4">
          ugly
        </label>
      </div>
      <div>
      <Footer/>
      </div>
    </div>
  );
}

export default Question;
