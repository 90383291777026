import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Terms from "../../Components/TermsConditions/Terms";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet titleTemplate="MMN | %s" title="Terms and Conditions" />
        <Header/>
        <Terms />
        <Footer/>
      </React.Fragment>
    );
  }
}

export default Home;
