import React from 'react'

const PCard = (props) => {
    return (
        <div style={{height:"300px", width:"15rem"}} className="-series-cartestd w-64 h-48 rounded-xl shadow-sm bg-gray-50 p-2 flex-col justify-center items-center space-y-2 transform hover:scale-105 duration-1000 ease-in-out">
            <div className="row-1">
                <div className="logo flex-row space-x-2 bg-orange-600 rounded-xl p-2 ">
                    <div className="cimage w-16 h-16 bg-cover  rounded-xl border-2  border-gray-50" style={ { backgroundImage: `url(./images/${props.src})` } }></div>
                    <div className="logo-name-cont flex-col justify-start">
                        <p className="series- text-md font-extrabold text-gray-50 w-36 truncate">{props.cat}</p>
                        <p className="series-description text-xs text-gray-50 w-max h-max bg-orange-800 pl-2 pr-2 p-1 rounded-full">{props.fig}k Students</p>
                </div>
                </div>
                <div className="active-c w-full  text-sm font-bold flex justify-center text-gray-800 "><p className="paid-txt text-red-400">{props.total} Total Tests</p>&nbsp; | &nbsp; <p className="free-test text-green-400">{props.free} Free Test</p></div>

            </div>

            <div className="row-2 flex justify-between">
                <div className="cat-detail">
            <p className="cat-det font-bold text-xs text-gray-600">Category Details: Tier-1</p>
            <p className="cat-det font-bold text-xs text-gray-600">{props.chap}&nbsp;Chapter Test:  Tier I</p>
                <p className="cat-det font-bold text-xs text-gray-600">{props.sec}&nbsp;Sectional Test: Tier I</p>
                <p className="cat-det font-bold text-xs text-gray-600">+{props.more}&nbsp;more tests</p>
                </div>
                <div className="button-sec flex items-end space-x-1 text-gray-50">
                    <div className="btn-1">   <button className="view- w-14 h-8   rounded-md bg-green-400"> View</button></div>
                    <div className="btn-1"><button className="view- w-8 h-8   rounded-br-xl rounded-md bg-green-400">+</button></div>
                </div>
            </div>
           
         
        </div>
        
    )
}

export default PCard;