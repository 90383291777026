import React from "react";
function Header1() {
  return (
    <div id="header1">
      <div className="menu-bar">
        <nav className="navbar nav-1 navbar-expand-lg navbar-light">
          <p id="p-txt">Sections &nbsp;&nbsp;</p> &nbsp;&nbsp;
          <button className="view-btn">Chapter Test</button>
          <div className="header-right">
            <p>View in</p>
            <div class="dropdown">
              <button
                class="dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                English
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item" href="#">
                    Hindi
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Tamil
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Spanish
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header1;
