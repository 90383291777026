import React, { useState } from "react";
import { Helmet } from "react-helmet";

// import Category1 from "../../Components/Dashboard/Category1";

// import TestCategory from "../../Components/Dashboard/CategoryTest";


import Navbar from "../../Components/Dashboard/Navbar";
// import TestCategory from "../../Components/Dashboard/CategoryTest";

// import Testimonials from "../../Components/Dashboard/Testimonials";
import MenuItem from "../../Components/Dashboard/MenuItem";
import TestSeries from "../../Components/Dashboard/TestSeries";
import PYP from "../../Components/Dashboard/PYP";
import Modal from "../../Components/Dashboard/Modal";



const About = () => {
  const [comp, setComp] = useState(<TestSeries />);
  const [mode, setMode] = useState("hidden");
  const [mdrop, setMDrop] = useState("flex-col");
  const [down, setDown] = useState("hidden");

  const mVisiblity = () => {
    setMode("flex");
  }
  const nVisiblity = () => {
    setMode("hidden");
  }




  return (
    <React.Fragment className="bg-gray-600 flex-col ">
      <Helmet titleTemplate="MMN | %s" title="Dashboard" />



      {/* ================== Main Dashboard Container =========================== */ }

      {/* ================== Nav Bar =========================== */ }
      <div className="header-ds flex h-16">
        <Navbar visible={ down } onClick={( )=>setDown((down === "hidden")?"flex-col":"hidden")}/>
      </div>

      {/* ================== Main Dashboard Sub Menu Container=========================== */ }
      <div className="app-body flex h-sub">

        {/* ================== Main Dashboard Left Side Menu =========================== */ }
        <div className="wrap-drop">
          <button className="menu-pop w-10 h-12 md:w-max-1/3  bg-gray-50 flex justify-center  items-center text-gray-700" onClick={ () => setMDrop((mdrop === "flex-col") ? "hidden" : "flex-col") }><span className="fa fa-arrow-right"></span></button>
          <div className={ `left-side bg-gray-50  md:flex-col w-min-0 w-max-1/3 justify-center items-center p-4 ${mdrop}` } >

            <section className={ `side-menu-contain  md:flex-col w-max space-y-8 ${mdrop}` }>
              <MenuItem item="Dashboard" iname="fas fa-tachometer-alt" onClick={ () => setComp(<PYP />) } />
              <MenuItem item="Test Series" iname="fas fa-server" onClick={ () => setComp(<TestSeries />) } />

              <MenuItem item="Course" iname="fa fa-swatchbook" />
              <MenuItem item="Attempted Test" iname="fas fa-check-double" />
              <MenuItem item="Quizzes" iname="fas fa-clipboard-list" />
              <MenuItem item="Practice" iname="fas fa-running" />
              <MenuItem item="PYP" iname="fas fa-file-alt" onClick={ () => setComp(<PYP />) } />

            </section>
          </div>
        </div>

        {/* ================== Main Dashboard right Side Diplay Area =========================== */ }
        <div className="Right-side w-full bg-gray-200 flex-col overflow-x-hidden overflow-y-scroll p-4 space-y-4">


          <div className="comp-w" onClick={ mVisiblity }>
            { comp }
          </div>
          <div className="pyp w-auto h-auto">

          </div>


          <div className="h-card-tray flex w-full overflow-x-scroll">



          </div>
          <div className={ mode } >
            <Modal visibility="invisble" onClick={ nVisiblity } />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default About;
