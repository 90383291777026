import React from 'react'

export default function Counsel() {
    return (
        <div id = "counsel">
            <div className="row">
                <div className="col-md-6">
                <h1 style={{fontSize:"2.5rem"}}>Experience Make My Notes Today</h1>
                <br />
                <h4>Get a Free Counselling with a single tap on bottom right of the screen below.</h4>
                <br />
              
                {/* <button className= "btn" onclick="EmbeddableWidget.mount({source_key: '7ba05d45-05e2-443d-be9c-15e1ad2da9c9', form_factor: 'button'});">Tap Bottom Right</button>
              */}
                </div>
                <div className="col-md-6">
                    
                </div>
            </div>
        </div>
    )
}
