import React from 'react';

const Modal =(props)=>{
    return(
        <div className="w-full h-screen animated fadeIn faster fixed flex  left-0 top-0  justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
            <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
            <div className="w-min-1/2  max-w-xl h-max p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
                {/* <!--content--> */}
                <div className=" flex-col items-between">
                    {/* <!--body--> */}

                    <div className="greet-cont flex-col justify-center w-auto h-auto space-y-2 text-center">
                        <div className="img"><img src="./images/13.png" alt="MakeMyNotes" className="w-64 h-auto" /></div>
                        <div className="img"><img src="./images/build.svg" alt="MakeMyNotes" className="w-72 h-auto" /></div>
                        <div className="heading"><p className=" text-lg sm:text-2xl font-bold">We are coming soon...</p></div>
                        <div className="heading w-72"><p className=" text-sm sm:text-md font-bold">We are working harder day and night to finish the development and serve you as soon as possible.</p></div>
                    </div>

            {/* footer */}
                    <div className="p-3  mt-2 text-center space-x-4 md:block">
                        <button className="mb-2 md:mb-0  px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-red-600" onClick={ props.onClick } style={ { backgroundColor:"#f78815"}}>Close</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Modal;